<template>
    <div v-loading=loading>
        <div>
            <el-page-header @back="goBack" content="会员信息">
            </el-page-header>
        </div>

        <!--内容-->
        <div style="padding-top: 50px">
            <el-table
                    :data="list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="registerMail"
                        label="用户邮箱"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="realName"
                        label="真实姓名"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>


                <el-table-column
                        prop="ipAddr"
                        label="注册IP"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="locationAddr"
                        show-overflow-tooltip
                        label="IP归属地"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="balanceNum"
                        show-overflow-tooltip
                        label="账户余额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="assetsNum"
                        show-overflow-tooltip
                        label="投资总额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="level"
                        show-overflow-tooltip
                        label="等级"
                        align="center">
                    <template slot-scope="scope">
                        <span v-html="scope.row.level+'级会员'"></span>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="注册时间"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="userStatus"
                        show-overflow-tooltip
                        label="用户状态"
                        align="center">
                    <template slot-scope="scope">
                        <el-tag effect="dark" :type="scope.row.userStatus==1?'':'danger'">
                            {{scope.row.userStatus==1?'正常':'已封禁'}}
                        </el-tag>
                    </template>
                </el-table-column>

<!--                <el-table-column-->
<!--                        align="center"-->
<!--                        fixed="right"-->
<!--                        label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="text" @click="lahei(scope.row.id)" :disabled="scope.row.userStatus==0">封禁</el-button>-->
<!--                        <el-button type="text" @click="toUserTeam(scope.row.id)">查看下级</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
        </div>

        <!--分页-->
        <div style="padding-top: 50px;text-align: center">
            <el-pagination
                    class="pagiantion"
                    :total="total"
                    @current-change="handleSelectionChange"
                    :current-page="user.pageIndex"
                    :page-size="user.pageSize"
                    layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        name: "AccountDetail",
        data() {
            return {
                loading: false,
                total: 0,
                user: {
                    pageIndex: 1,
                    pageSize: 5,
                    userId:null
                },
                list:[]
            }
        },
        methods: {
            findPage() {
                this.loading = true;
                this.axios.post('/user/findListByAgent', this.user).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            goBack() {
                this.$router.replace('/accountList')
            },
            handleSelectionChange(currentPage) {
                this.user.pageIndex = currentPage;
                this.findPage();
            },
        },
        created() {
            this.user.userId = this.$route.query.id;
            this.findPage();
        }
    }
</script>

<style scoped>

</style>
